.letra{
    font-size: 14px;
    /*font-family: 'DejaVu Sans', serif;*/
}

.eh1{
    font-size: 16px;
}

.ticket {
    margin: 2px;
}

.etd,
.eth,
.etr,
.etable {
    /*border-top: 1px solid black;*/
    border-collapse: collapse;
    margin: 0 auto;
}

.etd.precio {
    text-align: right;
    font-size: 12px;
}

.etd.cantidad {
    font-size: 12px;
}

.etd.producto {
    text-align: center;
}

.eth {
    text-align: center;
}


.centrado {
    text-align: center;
    align-content: center;
}


.eimg {
    max-width: inherit;
    width: inherit;
}
.eespacio{
    margin: 0;
    padding: 0;
}

.ticket {
    margin: 0;
    padding: 0;
}

.ebody {
    text-align: center;
}

.tinyLogo {
    width: 50px;
    height: 50px;
  }