.App {
  display: flex;
  margin: 0px;
  font-family: 'Source Sans Pro';
  flex-direction: column;
}
.contenedor{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.head{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.head > img{
  height: 55px;
  width: 55px;
  margin: 5px;
  margin-right: 10px;
}
.foot{
  display: flex;
  width: 100%;
  /*background: rgb(157,0,0);
  background: radial-gradient(circle, rgba(157,0,0,1) 12%, rgba(255,0,0,1) 70%);
  */
  
  background: rgb(33 37 41);
  color: white;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 3;
  bottom: 0%;
  position: fixed;
  height: 6%;
}
.texto-foot{
 font-size: 18px;
 flex: 1;
 font-weight: bold;
}
.titulo{
  margin: 5px;
  margin-left: 10px;
  font-size: 23px;
}
.img{
  display:flex;
  width:100%
}
.opciones{
  background: #f9fafb;
  border-bottom: 1px solid #9C9C9C;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
}
.nom_sistema{
  font-size: 18pt;
  font-family: "Lucida Handwriting"
}
.flex1{
  flex: 1;
}
.flex2{
  flex: 2;
}
.login{
  display: flex;
  flex:1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 2%;
}
.contenidoLogin{
  display: flex;
  /*overflow-y: scroll;*/
    flex: 1;
    width: 100%;
}
.cuerpoLogin{
  display: flex;
  flex: 10 1;
  margin-bottom: 24px;
  margin-top: auto;
  padding: 19px;
  background-color: #eaedf4;
}
.cuadroLogin{
  background-color: white;
  width: 100%;
  margin: 48px 100px;
  padding: 20px;
}
.butonsLogin{
  display: flex;
  flex: 1;
  justify-content: space-around;
}
.butonEntrar{
  width: 100%;
}
.containerLogin{
  text-align: center;
}
/*diseño para botones*/
.submenu {
  display:flex;
  flex:2;
  flex-direction:row;
  justify-content: flex-end;
  
}
.opciones .submenu {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.opciones .submenu a {
  color: #003B5C;
  text-decoration: none;
  padding-right: 15px;
}

.opciones .submenu a:hover {
  text-decoration: underline;

}
.boton_menu:hover {
  opacity: 0.8;
  }
  .boton_submenu{
    color: #212529;
    border: #04344e;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: none;
  }
  .boton_submenu:hover {
    opacity: 0.8;
  }
  .error{
    color: red;
}
/*css general*/
.contenido{
  display: flex;
  /*overflow-y: scroll;*/
  flex:1;
  width: 100%;
}
.sub_contenido{
  display: flex;
  flex:1;
  flex-direction: column;
  width: 100%;
  /*align-items: flex-start;
  */margin: 1%;
}
.filtros_pag{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 1%;
  margin-left: 0%;
  
}
.configuracionBotones{
  display: flex;
  justify-content: center;
}
/*.botonNuevo{
    background: url(./assets/iconos/nuevo.png) no-repeat scroll 0 0 transparent;
    cursor: pointer;
    color: #fff;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s;
    height: 26px;
    margin-right: 10px;
}*/

.botonVenta{
  background: url(./assets/iconos/savetime.png) no-repeat scroll 0 0 transparent;
  float: right;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.botonEditar, .botonBloqueoEdicion, .adjuntarAnexo{
  background: url(./assets/iconos/editar.png) no-repeat scroll 0 0 transparent;
  float: right;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.botonDelete{
  background: url(./assets/iconos/ico_equis.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.botonActive{
  background: url(./assets/iconos/validar.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.botonView{
  background: url(./assets/iconos/vista_previa.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.ticketBoton{
  background: url(./assets/iconos/order-bool-ascending-variant.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.botonBlock{
  background: url(./assets/iconos/lock.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.botonActivelog{
  background: url(./assets/iconos/lock-open.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.preciosBoton{
  background: url(./assets/iconos/precios.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.precioBoton{
  background: url(./assets/iconos/precio.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.masBoton{
  background: url(./assets/iconos/mas.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.menosBoton{
  background: url(./assets/iconos/menos.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  color: #fff;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
  height: 26px;
}
.alignDerecha{
  align-self: end;
  margin-bottom: 50px;
  width: 100%;
}
.numPaginas{
  width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.imgUsuarios{
  width: 29px;
    height: 29px;
}

.NotFounContent{
  display: flex;
    flex-direction: column;
    align-self: center;
}
.contenImagenNotFound{
  width: 520px;
}
.imagenNotFound{
  max-width: 100%;
  max-height: 100%;
}
.textoNotFound{
  align-self: center;
}
.centrarTexto{
  text-align: center;
}
.sub_contenidoCenter{
  display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 1%;
}
.contenedorQR{
  /*margin-top: -50px;*/
  width: "50%";
  height: "50vh";
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.qrReader{
  width: 30%;
  height: 50vh;

}
/*spinner*/
.modalTransparente{
  opacity: 0.8;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

/**/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (max-width: 600px) {
  .nom_sistema {
    font-size: 12pt;
    font-family: "Lucida Handwriting";
  }
  .cuadroLogin {
    background-color: white;
    width: 100%;
    margin: 26px 28px;
    padding: 20px;
}
}
@media (max-width: 300px) {
  .cuadroLogin {
    background-color: white;
    width: 100%;
    margin: 26px 28px;
    padding: 20px;
}
}
@media (max-width: 600px) and (min-width: 300px) {
  .qrReader{
    width: 90%;
    height: 50vh;
  }
}
@media (max-width: 800px) and (min-width: 601px) {
  .qrReader{
    width: 50%;
    height: 50vh;
  }
}
@media (max-width: 1000px) and (min-width: 801px) {
  .qrReader{
    width: 50%;
    height: 50vh;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*css extras*/
.fondoTabla{
  background-color: #f2f2f2;
}
.borderTabla{
  border: #dee2e6 1px solid;
  padding: 0.5rem;
}
.usuarioNom{
  display: flex;
    /* flex-direction: row; */
    /* width: 100%; */
    justify-content: flex-end;
    align-items: center;
    margin: 1%;
    /* margin-left: -21%; */
    margin-top: 1px;
    margin-bottom: -20px;
}
@media only screen and (max-width: 715px) and (min-width: 300px){
  .filtros_pag{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin: 1%;
    margin-left: 0%;
  }
  
}

